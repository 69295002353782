import React, { Component, Fragment, useState, useEffect } from 'react';
import { RecoilRoot, atom, selector, useRecoilState, useRecoilValue } from 'recoil';
import { FetchDataState, currentTab } from '../recoil/atoms/state';

import Tags from './tags';

const SearchTags = props => {
    const [fetchData, setFetchData] = useRecoilState(FetchDataState);
    const [currentTabId, setCurrentTabId] = useRecoilState(currentTab);
    
    return(
        <>
            {
                fetchData.tabs?.map((item, index) => {
                    // console.log(item);
                    return (
                        <div key={item.id} className={`search_tags ${currentTabId == item.id ? "" : "hidden"}`} data-id={item.id}>
                            <Tags hashtags={item.hashtags} />
                        </div>
                    )
                })
            }
        </>
    )
}

export default SearchTags;
