import React, { Component, Fragment, useState, useEffect } from 'react';
import { RecoilRoot, atom, selector, useRecoilState, useRecoilValue } from 'recoil';
import { FetchDataState, selectedTags } from '../recoil/atoms/state';

const Tags = props => {
    
    const [fetchData, setFetchData] = useRecoilState(FetchDataState);
    const [selectedTag, setSelectedTag] = useRecoilState(selectedTags);
    
    const setSelected = event => {
        if (event.currentTarget.classList.contains('selected')){

            // 含まれていれば削除
            if (selectedTag.includes(event.target.dataset.id)) {
                let selected = [...selectedTag]
                selected.some((value, i) => {
                    if (value === event.target.dataset.id) selected.splice(i, 1)
                })
                setSelectedTag(selected)
            }

        }else{
            if (selectedTag.length < 10 && ! selectedTag.includes(event.target.dataset.id)){
                setSelectedTag([...selectedTag, event.target.dataset.id]);
            }
        }
    }

    const checkActive = id => {
        let result = ''
        selectedTag?.find(value => {
            if (String(id) === value) {
                result = 'selected'
            }
        })
        return result
    }
    
    return (
        <ul>
            {
                props.hashtags?.map((item, index) => {
                    return(
                        <li key={item.id}
                            data-id={item.id}
                            onClick={setSelected}
                            className={checkActive(item.id)}
                        >
                            {item.name}
                        </li>
                    )
                })
            }
        </ul>
    )
}

export default Tags;