import { atom } from 'recoil';

// fetchしてきたデータ
export const FetchDataState = atom({
    key: 'FetchDataState',
    default: [{}],
});

// 現在選択されているタブのID
export const currentTab = atom({
    key: 'currentTab',
    default: 10,
});

// 選択されているハッシュタグの配列
export const selectedTags = atom({
    key: 'selectedTags',
    default: [],
});

// ハッシュタグに対応した企業の一覧
export const selectedCompanies = atom({
    key: 'selectedCompanies',
    default: [],
});
