import React, { Component, Fragment, useState, useEffect } from 'react';
import { RecoilRoot, atom, selector, useRecoilState, useRecoilValue } from 'recoil';
import { FetchDataState, selectedTags } from '../recoil/atoms/state';

const Selected = props => {

    const [fetchData, setFetchData]     = useRecoilState(FetchDataState)
    const [selectedTag, setSelectedTag] = useRecoilState(selectedTags)
    const selected                      = [...selectedTag]
    let   flat_tags                     = []

    fetchData.tabs?.map((value) => {
        flat_tags = flat_tags.concat(value.hashtags);
    })

    const searchName = (key, list) => {
        let name = null
        list.forEach(object => {
            if(object.id === parseInt(key)) {
                name = object.name
            }
        });
        return name
    }

    const deleteTag = (key) => {
        let selected = [...selectedTag]
        selected.some((value, i) => {
            if (value === key) selected.splice(i, 1)
        })
        setSelectedTag(selected)
    }

    return (
        <div className="selected_tags_wrap">
            <div className="selected_tags">
                <h3>選択したタグ</h3>
                <ul>
                    {
                        selected.map((item, key) => {
                            return(
                                <li key={key} data-id={item}>{searchName(item, flat_tags)}
                                    <span className="del" onClick={() => deleteTag(item)}></span>
                                </li>
                            )
                        })
                    }
                </ul>
                <p className="selected_count"><span>{selected.length}</span>/10</p>
            </div>
        </div>
    )
}

export default Selected;
