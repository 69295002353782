import React, { Component, Fragment, useState, useEffect } from 'react';
import { RecoilRoot, atom, selector, useRecoilState, useRecoilValue } from 'recoil';
import { FetchDataState } from '../recoil/atoms/state';
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
} from '@chakra-ui/react'

import Tags from './tags';

const Accordions = props => {
    
    const [fetchData, setFetchData] = useRecoilState(FetchDataState);
    
    return (
        <div className="sp_accordion">
            <div className="accordion_wrap">
            {/* <dl>
                {
                    fetchData.tabs?.map((item, index) => {
                        return (
                            <div className="accordion current" key={item.id}>
                                <dt>{item.name}</dt>
                                <dd>
                                    <Tags hashtags={item.hashtags} />
                                </dd>
                            </div>
                        )
                    })
                }
            </dl> */}
                <Accordion defaultIndex={[0]} allowToggle>
                    {
                        fetchData.tabs?.map((item, index) => {
                            return (
                                <div className="accordion" key={index}>
                                    <AccordionItem>
                                        <AccordionButton>
                                            <div className="accordion_header">
                                                {item.name}
                                            </div>
                                        </AccordionButton>
                                        <div className="accordion_body">
                                            <AccordionPanel pb={4}>
                                                <Tags hashtags={item.hashtags} />
                                            </AccordionPanel>
                                        </div>
                                    </AccordionItem>
                                </div>
                            )
                        })
                    }
                </Accordion>
            </div>
        </div>
    )
}

export default Accordions;