import React, { Component, Fragment, useState, useEffect } from 'react';
import { RecoilRoot, atom, selector, useRecoilState, useRecoilValue } from 'recoil';
import { FetchDataState, currentTab } from '../recoil/atoms/state';

const Tab = props => {
    
    const [fetchData, setFetchData] = useRecoilState(FetchDataState);
    const [currentTabId, setCurrentTabId] = useRecoilState(currentTab);
    
    const setCurrent = event =>{
        
        const current = Array.from(event.currentTarget.parentNode.children);
        // console.log(current);
        current.forEach(cur => {
            cur.classList.remove("current");
        })
        
        event.currentTarget.classList.add("current");
        
        const currentId = event.currentTarget.dataset.id;
        setCurrentTabId(currentId);
        
    }
    
    return (
        <nav className="tab">
            <ul>
                {
                    fetchData.tabs?.map((item, index) => {
                        return(
                            <li key={item.id} className={index === 0 ? "current" : ""} onClick={setCurrent} data-id={item.id}>{item.name}</li>
                        )
                    })
                }
            </ul>
        </nav>
    )
}

export default Tab;
