import React from 'react';
import { createRoot } from 'react-dom/client';
import { RecoilRoot } from 'recoil';

//コンポーネント
import Search from './search';


const App = props => {

    return (
        
        <RecoilRoot>
            <Search />
        </RecoilRoot>
        
    )

}

//レンダリング
const container = document.getElementById('search');
const root = createRoot(container);
root.render(<App />);