import React, { Component, Fragment, useState, useEffect } from 'react';
import { RecoilRoot, atom, selector, useRecoilState, useRecoilValue } from 'recoil';

const Result = props => {


    if (props.result.length > 0){

        return (
            <div className="search_result" id={"result-" + props.url }>
                <h3>あなたに合う企業・団体はこちら！</h3>

                <div className="search_result_inner">

                    {
                        props.result?.map((item, index) => {
                            return (
                                <a href={"/company/" + props.url + "/" + item.id} className="search_result_item" key={item.id}>
                                    <div className="search_result_item_head">
                                        <div className="corporate_logo">
                                            <img src={item.filepath ? item.filepath : "/common/img/icon_noimages.png"} alt={item.name} />
                                        </div>
                                        <h4>{item.name}</h4>
                                    </div>
                                    <div className="search_result_item_main">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th>募集専攻</th>
                                                    <td>
                                                        {
                                                            item.majors.map(major => {
                                                                return (
                                                                    <span key={major.id}>{major.name}</span>
                                                                )
                                                            })
                                                        }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <p className='keyword'>
                                            {
                                                item.occupations.map((occupation, index) => {
                                                    return (
                                                        <span key={index}>{occupation.name}</span>
                                                    )
                                                })
                                            }
                                        </p>
                                        <p className="corporate_tags">
                                            {
                                                item.hashtags.map(hashtag => {
                                                    if(props.selectedTag.includes(String(hashtag.id))) {
                                                        return (
                                                            <span key={hashtag.id}>{hashtag.name}</span>
                                                        )
                                                    }
                                                })
                                            }
                                        </p>
                                    </div>
                                </a>
                            )
                        })
                    }

                </div>
            </div>
        )

    }else{
        return null
    }



}

export default Result;
